import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import {
  Container,
  Box,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { TransitionState } from 'gatsby-plugin-transition-link'
import Lottie from 'react-lottie'
import * as animationData from '../../assets/lottie/logo-data.json'
import Layout from '../../components/Layout'
import TriNetIcon from '../../components/graphics/TriNetIcon'
import Circle from '../../components/graphics/Circle'
import Triangle from '../../components/graphics/Triangle'
// import CardGrid from '../../components/CardGrid'
import TitleHighlight from '../../components/TitleHighlight'
// import ContentCard from '../../components/ContentCard'
import Image from '../../components/PreviewCompatibleImage'
import IntersectBox from '../../components/IntersectBox'
import makeTexture from '../../components/graphics/Texture'
import NewsletterBlock from '../../components/NewsletterBlock'

const useStyles = makeStyles((theme) => ({
  main: {
    transition: 'all 0.45s ease',
    opacity: 0,
    transform: 'translate(-20px,20px)',
  },
  entered: {
    opacity: 1,
    transform: 'translate(0px,0px)',
  },
  entering: {
    opacity: 0,
    transform: 'translate(-20px,20px)',
  },
  exiting: { opacity: 0, transform: 'translate(0px,0px)' },
  exited: { opacity: 0, transform: 'translate(0px,0px)' },
  delayEnter: {
    opacity: 0,
    transform: 'translate(0px,15px)',
    transition: 'all 0.5s ease',
    '&$active': {
      opacity: 1,
      transform: 'translate(0px,0px)',
    },
  },
  active: {},
  heroTitle: {
    fontFamily: 'permanent_markerregular',
    fontSize: '3.125rem',
    lineHeight: '100%',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.875rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '4.5rem',
    },
  },
  heroTitleContainer: {
    display: 'flex',
  },

  sectionOneTitle: {
    fontFamily: 'permanent_markerregular',
    fontWeight: '900',
    fontSize: 32,
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      fontSize: 60,
      marginRight: 60,
    },
  },
  markerFont: {
    fontFamily: 'permanent_markerregular',
  },
  titleOne: {
    fontSize: 50,
    fontWeight: '900',
    [theme.breakpoints.up('md')]: {
      fontSize: 72,
    },
  },
  underline: {
    backgroundImage: 'url("/underline.svg")',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    color: '#f2f3f8',
    paddingRight: '8%',
    paddingLeft: '5%',
    marginLeft: '-5%',
    lineHeight: '130%',
  },
}))

const splitWord = (str) => {
  const words = str.split(' ')
  const start = words.slice(0, 1).join(' ')
  const end = words.slice(1, words.length).join(' ')
  return { start, end }
}

const AboutTemplate = ({
  title,
  subtitle,
  // creatorsSubtitle,
  mainImage,
  texture,
  paragraphOneTitle,
  paragraphOneAText,
  paragraphOneBText,
  paragraphOneCText,
  boldText,
  paragraphTwoTitle,
  paragraphTwoAText,
  paragraphTwoBText,
  // creatorsTitle,
  // creators,
  isEntered,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const titleWords = splitWord(title)
  const paragraphOneTitleWords = splitWord(paragraphOneTitle)
  const paragraphTwoTitleWords = splitWord(paragraphTwoTitle)
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  const [animationStarted, setAnimationStarted] = useState(false)
  const Texture = makeTexture(texture)

  useEffect(() => {
    let beginAnimTimeout
    if (isEntered) {
      beginAnimTimeout = setTimeout(() => {
        setAnimationStarted(true)
      }, 100)
    }

    return () => {
      if (beginAnimTimeout) clearTimeout(beginAnimTimeout)
    }
  }, [isEntered])
  const isSmOrBelow = useMediaQuery((mqTheme) => mqTheme.breakpoints.down('sm'))
  // const isLgOrBelow = useMediaQuery((mqTheme) => mqTheme.breakpoints.down('lg'))

  return (
    <Container maxWidth="xl" style={{ position: 'relative' }}>
      <Box position="absolute" bottom="0" right="0" width="50%">
        <Texture variation="blue" />
      </Box>
      <Box
        display={{ xs: 'none', sm: 'initial' }}
        position="absolute"
        top={90}
        right={-160}
        width={300}
        height={300}
        style={{ opacity: 0.4 }}
      >
        <Circle />
      </Box>
      <Box
        display={{ xs: 'none', sm: 'initial' }}
        position="absolute"
        top={150}
        left={-120}
        width={300}
        height={275}
        style={{ opacity: 0.4 }}
      >
        <Triangle mixBlendMode="normal" color={theme.palette.primary.main} />
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center">
        <Box maxWidth={{ xs: '25%', sm: 146, md: 186 }} mt={2} style={{ transform: 'translateY(10%)' }}>
          <Lottie
            options={defaultOptions}
            isClickToPauseDisabled
            height={{ xs: '25%', sm: 146, md: 186 }}
            width={{ xs: '25%', sm: 146, md: 186 }}
            isPaused={!isEntered}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          textAlign="center"
          mx={{ xs: 4, md: 7 }}
          maxWidth={689}
          justifyContent="center"
          style={{ transitionDelay: '0.6s' }}
          className={`${classes.delayEnter} ${
            animationStarted ? classes.active : ''
          }`}
          position="relative"
        >

          <Box className={classes.heroTitleContainer}>
            <Typography className={classes.heroTitle} variant="h1">
              <span>{titleWords.start}</span>
              {' '}
              <Box display="inline-flex">
                <span className={classes.underline}>{titleWords.end}</span>
              </Box>
            </Typography>
          </Box>
        </Box>
        <Box
          textAlign="center"
          mt={{ xs: 3, md: 5 }}
          maxWidth={{ xs: 320, md: 415 }}
          className={`${classes.delayEnter} ${
            animationStarted ? classes.active : ''
          }`}
          style={{ transitionDelay: '0.8s' }}
        >
          <Typography variant="h4" component="h2">{subtitle}</Typography>
        </Box>
      </Box>
      <Container maxWidth="lg">

        <Box
          display="flex"
          alignItems="center"
          maxWidth="lg"
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
          position={{ md: 'relative' }}
          mt={{ xs: 4, sm: 13 }}
        >
          <Box position="absolute" top="0" left="0" width="50%">
            <Texture variation="blue" />
          </Box>
          <Box position="absolute" top="0" right="0" width="50%">
            <Texture variation="grey" />
          </Box>
          <IntersectBox
            type="riseTrigger"
            threshold={0.5}
            style={{
              width: isSmOrBelow ? '100%' : '50%',
              display: 'flex',
              'align-items': 'center',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              width={{ md: '100%' }}
              maxWidth="460px"
              mr={{ md: 10, lg: 12 }}
              ml={{ md: 'auto' }}
              className="nested-rise-transition-item"
            >
              <Typography variant="h2" className={classes.sectionOneTitle}>
                <span className={classes.markerFont}>{paragraphOneTitleWords.start}</span>
                {' '}
                <span className={classes.markerFont}>
                  {paragraphOneTitleWords.end}
                </span>
              </Typography>
              <Box mb={2}>
                <Typography variant="body2">{paragraphOneAText}</Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body2">{paragraphOneBText}</Typography>
              </Box>
              <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                {paragraphOneCText}
              </Typography>
            </Box>
          </IntersectBox>
          <IntersectBox
            type="riseTrigger"
            threshold={0.5}
            style={{ width: isSmOrBelow ? '60%' : '50%' }}
          >
            <Box
              display="flex"
              width={{ xs: '100%' }}
              maxWidth={{ xs: '346px', md: '875px' }}
              position="relative"
              zIndex="-1"
              className="nested-scale-transition-item"
            >
              <Image imageInfo={mainImage} style={{ width: '100%' }} />
              <Box
                position="absolute"
                bottom={{ xs: '-1em', md: 0 }}
                right={{ xs: '-4em', md: '-5em' }}
                maxWidth={{
                  xs: 186,
                  md: '50%',
                }}
                width="100%"
              >
                <div
                  style={{
                    background: theme.palette.primary.main,
                    borderRadius: '50%',
                    width: '20vw',
                    height: '20vw',
                    opacity: 0.5,
                  }}
                />
              </Box>
            </Box>
          </IntersectBox>
        </Box>
      </Container>
      <Box my={{ xs: 8, md: 12 }}>
        <Container maxWidth="lg" mb={{ xs: 2.75, md: 8.25 }}>
          <TitleHighlight
            title={boldText}
            breakIndex={1}
            highlightIndex={0}
            shouldExit
          />
        </Container>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          mt={{ xs: 6, md: 17.5 }}
        >
          <Box
            display="flex"
            width={{ xs: '80%', md: '50%' }}
            position="relative"
            zIndex="-1"
          >
            <Box position="absolute" top="0" left="0" width="50%" zIndex="10">
              <Texture variation="blue" />
            </Box>
            <Box position="absolute" top="0" right="0" width="50%" zIndex="10">
              <Texture variation="grey" />
            </Box>
            <IntersectBox
              type="slideBox"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '75%',
              }}
              rootMargin="50% 0% 0% 0%"
              intersectComponentStyles={{ width: '100%' }}
            >
              <TriNetIcon />
            </IntersectBox>
          </Box>
          <IntersectBox
            type="riseTrigger"
            threshold={0.5}
            style={{
              width: isSmOrBelow ? '85%' : '50%',
              marginLeft: '24px',
              display: 'flex',
              'align-items': 'center',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              maxWidth={{ xs: 500, md: 579 }}
              ml={{ md: 10, lg: 12 }}
              mt={{ xs: 4, md: 0 }}
              mx={{ xs: 'auto', md: 'unset' }}
              className="nested-rise-transition-item"
            >
              <Typography variant="h3" component="h2" className={classes.sectionOneTitle}>
                <span>{paragraphTwoTitleWords.start}</span>
                {' '}
                <Box display="inline-flex">
                  <span className={classes.underline}>{paragraphTwoTitleWords.end}</span>
                </Box>
              </Typography>
              <Box mb={2}>
                <Typography variant="body2">{paragraphTwoAText}</Typography>
              </Box>
              <Typography variant="body2">{paragraphTwoBText}</Typography>
            </Box>
          </IntersectBox>
        </Box>

        {/* <IntersectBox type="fadeTrigger">
          <Box
            mt={{ xs: 7.5, md: 10 }}
            mb={{ xs: 5, md: 10 }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            position="relative"
          >
            <Box position="absolute" top="0" right="0" width="50%">
              <Texture variation="grey" />
            </Box>
            <Box position="absolute" top="0" right="0" width="50%">
              <Texture variation="grey" />
            </Box>
            <Box position="relative" top="5px">
              <Typography variant="h3">{creatorsSubtitle}</Typography>
            </Box>
            <Box>
              <Typography variant="h1" className={classes.markerFont}>
                {creatorsTitle}
              </Typography>
            </Box>
          </Box>
        </IntersectBox> */}
      </Box>

      {/* <Box position="relative">
        <Box position="absolute" top="0" right="0" width="50%">
          <Texture variation="grey" />
        </Box>
        <Box position="absolute" top="0" left="0" width="50%">
          <Texture variation="blue" />
        </Box>
        <Container maxWidth="xl" style={{ marginBottom: isLgOrBelow ? 40 : 0 }}>
          <CardGrid>
            {creators.map(
              ({
                title: cardItemTitle,
                subtitle: cardItemSubtitle,
                description,
                imageFile,
              }) => (
                <ContentCard
                  key={title}
                  to=""
                  thumbnail={imageFile}
                  type="listen"
                  title={cardItemTitle}
                  description={description}
                  subtitle={cardItemSubtitle}
                  eyebrow={false}
                  flipTitle
                  descLimit={300}
                  hoverEffect={false}
                />
              ),
            )}
          </CardGrid>
        </Container>
      </Box> */}
    </Container>
  )
}

AboutTemplate.propTypes = {
  texture: PropTypes.shape({
    blue: PropTypes.shape({}),
    green: PropTypes.shape({}),
    grey: PropTypes.shape({}),
  }).isRequired,
  mainImage: PropTypes.shape({
    childImageSharp: PropTypes.shape({ fluid: PropTypes.shape({}) }),
  }).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  // creatorsSubtitle: PropTypes.string.isRequired,
  paragraphOneTitle: PropTypes.string.isRequired,
  paragraphOneAText: PropTypes.string.isRequired,
  paragraphOneBText: PropTypes.string.isRequired,
  paragraphOneCText: PropTypes.string.isRequired,
  boldText: PropTypes.string.isRequired,
  paragraphTwoTitle: PropTypes.string.isRequired,
  paragraphTwoAText: PropTypes.string.isRequired,
  paragraphTwoBText: PropTypes.string.isRequired,
  // creatorsTitle: PropTypes.string.isRequired,
  // creators: PropTypes.arrayOf(PropTypes.shape).isRequired,
  isEntered: PropTypes.bool.isRequired,
}

const AboutPage = ({
  data: {
    textureBlue,
    textureGreen,
    textureGrey,
    pageData: {
      frontmatter: {
        title,
        mainImageFile,
        mainImageAltText,
        subtitle,
        paragraphOneTitle,
        paragraphOneAText,
        paragraphOneBText,
        paragraphOneCText,
        boldText,
        paragraphTwoTitle,
        paragraphTwoAText,
        paragraphTwoBText,
        creatorsTitle,
        creatorsSubtitle,
        creators,
      },
    },
  },
}) => {
  const classes = useStyles()
  useEffect(() => {
    window.dataLayer.push({ page: null })
    window.dataLayer.push({
      event: 'page_view',
      page: {
        '@type': 'AboutPage',
        breadcrumb: 'Home > About Us',
        id: 'about-us',
        name: 'About Us Page',
        page_category: 'AboutPage',
      },
    })
  }, [])
  return (
    <Layout>
      <Box overflow="hidden">
        <TransitionState>
          {({ transitionStatus }) => (
            <Box
              className={`${classes.main} ${classes[transitionStatus]}`}
              mx={{ xs: 2, md: 0 }}
            >
              <AboutTemplate
                title={title}
                mainImage={mainImageFile}
                mainImageAltText={mainImageAltText}
                subtitle={subtitle}
                texture={{
                  blue: textureBlue,
                  green: textureGreen,
                  grey: textureGrey,
                }}
                paragraphOneTitle={paragraphOneTitle}
                paragraphOneAText={paragraphOneAText}
                paragraphOneBText={paragraphOneBText}
                paragraphOneCText={paragraphOneCText}
                boldText={boldText}
                paragraphTwoTitle={paragraphTwoTitle}
                paragraphTwoAText={paragraphTwoAText}
                paragraphTwoBText={paragraphTwoBText}
                creatorsTitle={creatorsTitle}
                creatorsSubtitle={creatorsSubtitle}
                creators={creators}
                isEntered={transitionStatus === 'entered'}
              />
              <NewsletterBlock />
            </Box>
          )}
        </TransitionState>
      </Box>
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    textureBlue: PropTypes.shape({}).isRequired,
    textureGreen: PropTypes.shape({}).isRequired,
    textureGrey: PropTypes.shape({}).isRequired,
    pageData: PropTypes.shape({
      frontmatter: {
        mainImageFile: PropTypes.shape({}).isRequired,
        components: PropTypes.shape({}).isRequired,
      },
    }).isRequired,
  }).isRequired,
}

export const pagesQuery = graphql`
  query aboutPage {
    textureBlue: file(relativePath: { eq: "textureblue.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    textureGreen: file(relativePath: { eq: "texturegreen.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    textureGrey: file(relativePath: { eq: "texturegrey.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pageData: markdownRemark(fields: { slug: { eq: "/pages/about/" } }) {
      frontmatter {
        title
        mainImageFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subtitle
        paragraphOneTitle
        paragraphOneAText
        paragraphOneBText
        paragraphOneCText
        boldText
        paragraphTwoTitle
        paragraphTwoAText
        paragraphTwoBText
        creatorsTitle
        creatorsSubtitle
        creators {
          title
          subtitle
          description
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageAltText
        }
      }
    }
  }
`

export { AboutTemplate }
export default AboutPage
